import { svgs } from '@/utils/Images'
import { Action1 } from '@/utils/Types'
import { HTMLAttributes, InputHTMLAttributes, ReactElement, ReactNode } from 'react'

export function Checkbox({
  checked,
  onClick,
  onChange,
  input,
  span,
  extraCls,
  innerCls,
  text,
}: {
  checked?: boolean
  onClick?: Action1<any>
  onChange?: Action1<any>
  input?: InputHTMLAttributes<HTMLInputElement>
  span?: HTMLAttributes<HTMLSpanElement>
  extraCls?: string
  innerCls?: string
  text?: ReactNode
}): ReactElement {
  return (
    <label className={`relative flex cursor-pointer items-center pl-5 ${extraCls || ''}`}>
      <input type='checkbox' className='peer hidden' checked={checked} onClick={onClick} onChange={onChange} {...input} />
      <span
        {...span}
        className={`absolute left-0 top-1/2 h-4 w-4 -translate-y-1/2 cursor-pointer rounded-sm border border-neutral-600 bg-white/100 text-white/100 peer-checked:border-transparent peer-checked:bg-cyan-v2 peer-checked:hover:bg-amber-v1 peer-disabled:cursor-not-allowed peer-disabled:bg-neutral-300 peer-disabled:text-neutral-300 ${
          innerCls ?? ''
        }`}
      >
        <svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4' viewBox='0 0 24 24' fill='currentColor'>
          {svgs.check_box}
        </svg>
      </span>
      {text}
    </label>
  )
}
export default Checkbox
