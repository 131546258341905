import { useLanguage } from '@/hooks/Contexts'
import { useScrollable } from '@/hooks/Index'
import { Action1 } from '@/utils/Types'
import { InputHTMLAttributes, useState } from 'react'
import CaretIcon from '../CaretIcon'
import { TextInputProps } from './props'

export function SearchBarDropdown({
  outerCls,
  label,
  labelCls,
  innerCls,
  required,
  input,
  defOptions,
  searchable,
  onSelect,
  closeAnimate,
}: {
  onSelect: Action1<string>
  defOptions: Array<string>
  searchable?: boolean
  closeAnimate?: boolean
} & Omit<TextInputProps, 'showValidated' | 'valid' | 'tipMessage' | 'visibility'>) {
  const {
    user: { search_here },
  } = useLanguage()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<string[]>(defOptions)

  useScrollable(isOpen)

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...input,
    onClick: e => {
      e.stopPropagation()
      setIsOpen(!isOpen)
    },
  }
  return (
    <div className={`relative ${outerCls || ''}`} onMouseLeave={() => setIsOpen(false)}>
      {label ? (
        <label className={`font-medium text-black ${labelCls || ''}`}>
          {label}
          {required && <span className='ml-1 text-error'>*</span>}
        </label>
      ) : null}
      <div
        className={`mt-2 flex items-center border-2 bg-white/100 px-2 py-2 text-sm shadow-lg shadow-stone-200 transition md:px-4 ${
          isOpen ? 'rounded-t-md border-b-2 border-x-cyan-v2 border-t-cyan-v2' : 'rounded-md border-stone-200'
        } ${innerCls || ''}`}
      >
        {searchable ? (
          <input
            {...inputProps}
            placeholder={search_here}
            onChange={e => {
              setOptions(defOptions.filter(option => option.toLowerCase().includes(e.target.value.toLowerCase())))
            }}
            className={`text-sm outline-none md:text-base ${input.className || ''}`}
          />
        ) : (
          <input {...inputProps} readOnly className={`text-sm outline-none md:text-base ${input.className || ''}`} />
        )}
        <CaretIcon contentOpen={isOpen} className='h-4 w-4' onClick={() => setIsOpen(!isOpen)} />
      </div>
      <ul
        className={`absolute top-full z-10 max-h-48 w-full overflow-y-auto rounded-b-md border-x-2 border-b-2 border-cyan-v2 bg-white/100 transition ${
          isOpen ? 'animate-[fade-in-down_150ms_ease-out]' : closeAnimate ? 'animate-[fade-out-up_150ms_ease-out_forwards]' : 'hidden'
        }`}
      >
        {options.map((option, index) => (
          <button
            type='button'
            key={index}
            onClick={() => {
              setIsOpen(false)
              onSelect(option)
            }}
            className={`w-full border-b bg-white/100 px-2 py-2 text-left transition last:rounded-b-md last:border-0 hover:bg-amber-v2 md:px-4`}
          >
            {option}
          </button>
        ))}
      </ul>
    </div>
  )
}
export default SearchBarDropdown
